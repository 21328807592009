import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import CTopbarMenu from "./CTopbarMenu";
import {useNavigate} from "react-router-dom";
import {UserType} from "../types/TUserData";

export function CLoggedInTopbarMenuDesktop() {
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();

  const [topNavbarSelectedPage, setTopNavbarSelectedPage] = useState('')
  useEffect(() => {
    const loc = window.location.href
    if (loc.includes('/dukkan')) {
      setTopNavbarSelectedPage('dukkan')
    } else if (loc.includes('/dashboard') || loc.includes('/profile')) {
      setTopNavbarSelectedPage('pofft')
    }
  }, []);

  function profileMenuItems() {
    const list = [];
    list.push({text: mainContext?.getTranslation('menu', 'anasayfa') ?? '', link: "/dashboard"})
    list.push({text: mainContext?.getTranslation('menu', "profilim") ?? '', link: "/profile"})
    list.push({text: mainContext?.getTranslation('menu', 'ayarlar') ?? '', link: "/profile/settings"})
    list.push({
      text: mainContext?.getTranslation('menu', 'cikis-yap') ?? '', func: () => {
        mainContext?.logout();
        navigate('/')
      }
    })
    return list
  }

  const profileMenu = {
    title: <div className={'rounded-full bg-gray-300 min-w-[40px] min-h-[40px]'}><img style={{backgroundColor: '#D9D9D9'}} src={mainContext?.userData?.picture}/></div>,
    link: "/",
    items: profileMenuItems(),
  };

  const applicationMenu = {
    title: <div className="flex gap-[12px] items-center" role={'button'}>
      <img src="/images/application.svg"/>
      <div
        className="text-grey text-14-16-500">{mainContext?.getTranslation('menu', (mainContext?.userData?.userType && mainContext.userData.userType == UserType.Professional) ? 'basvurularim' : 'gelen-teklifler')}</div>
    </div>,
    link: "/",
    items: [
      ...(mainContext?.userData?.userType && mainContext.userData.userType == UserType.Professional) ? [{
        text: mainContext?.getTranslation('menu', 'basvurularim') ?? '',
        link: "/applications"
      }] : [],
      {
        text: mainContext?.getTranslation('menu', 'gelen-teklifler') ?? '',
        link: mainContext?.userData?.userType == UserType.Professional ? "/incoming-offers" : '/profile?p=offers'
      },
      {text: mainContext?.getTranslation('menu', 'odemeler') ?? '', link: "/payments"},
      ...(mainContext?.userData?.userType && mainContext.userData.userType == UserType.Company) ? [
        {text: mainContext?.getTranslation('menu', 'ilan-olustur') ?? '', link: "/project-upsert"}
      ] : [],
    ],
  };

  return (
    <div className="flex justify-end gap-[24px] items-center">
      <div className="flex gap-[12px] items-center" role={'button'} onClick={() => window.location.href = '/dashboard'}>
        <img src="/images/search_black.svg"/>
        <div className="text-black text-14-16-500">{mainContext?.getTranslation('menu', 'is-ara')}</div>
      </div>

      <div className="rounded-full overflow-y-hidden  h-[40px] flex items-center">
        <CTopbarMenu data={applicationMenu}/>
      </div>

      <div className="flex justify-center items-center">
        {(mainContext?.messages.length ?? 0) > 0 ? (
          <img role={'button'} src="/images/message_unread.svg" onClick={() => {
            navigate('/messages')
          }}/>
        ) : (
          <img role={'button'} src="/images/message_unread.svg" onClick={() => {
            navigate('/messages')
          }}/>
        )}
      </div>
      {/*UNCOMMENT: bildirimler*/}
      {/*<div className="flex justify-center items-center">*/}
      {/*  {(mainContext?.notifications.length ?? 0) > 0 ? (*/}
      {/*    <img src="/images/notification_unread.svg"/>*/}
      {/*  ) : (*/}
      {/*    <img src="/images/notification_unread.svg"/>*/}
      {/*  )}*/}
      {/*</div>*/}

      {mainContext?.userData?.userType == UserType.Professional &&
          <div className="flex justify-center items-center" onClick={()=>{
            window.location.href=('/dashboard/liked')
          }}>
              <img src="/images/LikedMenu.svg"/>
          </div>
      }

      <div className={'topbarSwitcher'}>
        <div role={'button'} className={`item ${topNavbarSelectedPage != 'dukkan' ? 'selected' : ''}`}
             onClick={() => {
               if (topNavbarSelectedPage != 'pofft') {
                 window.location.href = '/dashboard'
               }
             }}>
          pofft
        </div>
        <div role={'button'} className={`item ${topNavbarSelectedPage == 'dukkan' ? 'selected' : ''}`}
             onClick={() => {
               if (topNavbarSelectedPage != 'dukkan') {
                 window.location.href = '/comingsoon'
               }
             }}>
          {mainContext?.getTranslation('menu', 'dukkan')}
        </div>
      </div>

      <div className="rounded-full overflow-hidden bg-gray-300 w-[40px] h-[40px] flex items-center">
        <CTopbarMenu data={profileMenu}/>
      </div>
    </div>
  );
}
