import React, {useEffect, useState} from "react";
import {PageState} from "../types/Context";
import {TMessage, TNotification} from "src/types/TMenu";
import {basicHeaders, getV2, setPublicToken, setUserToken, signInRequest} from "src/utils/request";
import {parseTranslation as parseTranslation, Translation} from "src/types/Translation";
import {TUserData} from "../types/TUserData";
import {TError} from "../types/TError";
import {TMediaObject} from "../types/TMediaObject";
import {
  NotificationType,
  ShowManualToast,
  ShowToast,
} from "../components/Toast/Toast";
import {mapToastTranslations} from "../utils/toatsts";
import {enqueueSnackbar} from "notistack";
import {getTranslations} from "../service/commonRequest";
import {getCookie, setCookie} from "../utils/cookie";

export interface MainContextType {
  publicToken: string | undefined,
  userData: TUserData | undefined,
  setUserData: (data: TUserData|undefined) => void;
  userToken?: string
  logout: () => void
  signinupState: PageState;
  setSigninupState: (value: PageState) => void;
  loggedIn: boolean;
  notifications: TNotification[];
  setNotifications: (value: TNotification[]) => void;
  messages: TMessage[];
  setMessages: (value: TMessage[]) => void;
  translationsLoaded: boolean;
  isExpired: (msg: string) => boolean;
  isExpiredLogin: boolean;
  setIsExpiredLogin: (val: boolean) => void

  getTranslation(parent: string, child: string): string;

  getBackendTranslation(child: string): string;

  updateUserPhoto(photo: TMediaObject): void

  showToast(type: NotificationType, message?: string,keyAsMessage?:boolean): void

  seeAllJobs: () => void
}

export const MainContext = React.createContext<MainContextType | null>(null);

let timer: any | undefined = undefined
let tokenChecker: Function | undefined = undefined

export const MainContextProvider = ({children}: any) => {

  const [signinupStateLater, SetSigninupState] = useState(PageState.None);
  const [isExpiredLogin, setIsExpiredLogin] = useState(false)
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [commonTranslations, setCommonTranslations] =
    useState<Map<string, Translation> | undefined>(undefined);
  const [publicToken, SetPublicToken] = useState<string | undefined>(undefined)
  const [userData, SetUserData] = useState<TUserData | undefined>(undefined)
  const [userToken, SetUserToken] = useState<undefined | string>(undefined)
  const loggedIn = userData != undefined

  useEffect(() => {
    const userDataStr = localStorage.getItem('userData')
    if (userDataStr && userDataStr.length > 0) {
      let parsed = JSON.parse(userDataStr);
      setUserData(parsed)
    }
    signInRequest(basicHeaders.auth.username, basicHeaders.auth.password).then(r => {
      if (!(r instanceof TError)) {
        setPublicToken(r.token)
        SetPublicToken(r.token)
        getTranslations('common', (translations, data) => {
          setCommonTranslations(translations)
          mapToastTranslations(translations)
        })
      }
    })
  }, []);

  function showToast(type: NotificationType, key?: string, keyAsMessage: boolean = false) {
    if (keyAsMessage == true && key != undefined) {
      ShowManualToast(enqueueSnackbar, type, key)
      return
    }
    ShowToast(enqueueSnackbar, type, key)
  }

  function seeAllJobs() {
    if (userToken != undefined && userData != undefined) {
      if (isExpired('')) {
        //Token is expired and will automatically show login modal
      } else {
        window.location.href = '/dashboard'
      }
    } else {
      setSigninupState(PageState.Signin)
    }
  }

  function setSigninupState(val: PageState, isExpired?: boolean) {
    SetSigninupState(val)
    setIsExpiredLogin(isExpired == true)
  }

  function setUserData(data?: TUserData) {
    SetUserData(data)
    if (data == undefined) {
      localStorage.removeItem('userData')
    } else {
      setUserToken(data.token)
      SetUserToken(data.token)
      localStorage.setItem('userData', JSON.stringify(data))
      try {
        const cookie = getCookie(document,'next-auth.session-token')
        debugger
        console.log(`found cookie: ${cookie}`)
        setCookie(document,'next-auth.session-token', 'test')
        console.log(`set cookie: deneme`)
      }catch (e) {
        debugger
      }
    }
  }

  function isExpired(msg: string): boolean {
    var val = false
    if (userData) {
      const expD = new Date(userData.expireDate).getTime() / 1000
      const nowD = new Date().getTime() / 1000

      val = expD - nowD < 60
    }
    if (val) {
      console.log('expired: ' + msg)
      setSigninupState(PageState.Signin)
    }
    return val
  }

  function logout() {
    setUserData(undefined)
  }

  useEffect(() => {
    if (signinupStateLater == PageState.Signin) {
      // window.location.href=("https://login.pofft.com/tr-TR/Login")
    } else if (signinupStateLater == PageState.Signup) {
      // window.location.href = ("https://login.pofft.com/tr-TR/Professional/Register")
    }
  }, [signinupStateLater])


  // const signinupState = PageState.None;
  const signinupState = signinupStateLater;

  function getTranslation(parent: string, child: string): string {
    return (commonTranslations?.get(parent)?.sentences.get(child) ?? "");
  }

  function getBackendTranslation(child: string): string {
    return commonTranslations?.get('backend')?.sentences.get(child.toLowerCase()) ?? "";
  }

  function updateUserPhoto(photo: TMediaObject) {
    if (userData) {
      const oldData = JSON.parse(localStorage.getItem('userData') ?? '{}')
      oldData.picture = photo.url
      localStorage.setItem('userData', JSON.stringify(oldData))
      setUserData({...userData, picture: photo.url})
    }
  }

  const translationsLoaded = commonTranslations != undefined;

  useEffect(() => {
    if (timer != undefined) {
      clearTimeout(timer)
    }
    tokenChecker = () => {
      if (signinupState != PageState.Signin) {
        if (userData != undefined && userData && userData.token) {
          const expD = new Date(userData.expireDate).getTime() / 1000
          const nowD = new Date().getTime() / 1000
          //berkExpire
          if (expD - nowD < 50) {
            console.log('tc: expire')
            setTimeout(() => {
              setSigninupState(PageState.Signin, true)
            }, 500)
          }
        }
      }
      if (tokenChecker) {
        setTimeout(tokenChecker, 10000)
      }
    }
    timer = setTimeout(tokenChecker, 10000)
  }, [userData, signinupState]);

  return (
    <MainContext.Provider
      value={{
        publicToken,
        userData,
        setUserData,
        logout,
        signinupState,
        setSigninupState,
        loggedIn,
        notifications,
        setNotifications,
        messages,
        setMessages,
        getTranslation,
        getBackendTranslation,
        translationsLoaded,
        userToken,
        isExpired,
        isExpiredLogin,
        setIsExpiredLogin,
        updateUserPhoto,
        showToast,
        seeAllJobs
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
